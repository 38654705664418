(function () {
    'use strict';

    angular.module('common')
        .factory("allPermissions", allPermissions);

    allPermissions.$inject = ['$http', 'CommonService', 'SessionService', 'rbacService', 'rolesConstant'];

    function allPermissions($http, CommonService, SessionService, rbacService, rolesConstant) {
        var service = {
            getAllPermissions: getAllPermissions
        };

        function getAllPermissions() {
            return CommonService.getAllPermissions()
                .then(function (types) {

                    var allPermissions = loadPermissions(types);
                    setAppRolesByPermissions(allPermissions);
                    return allPermissions;
                });
        }

        function setAppRolesByPermissions(allPermissions) {
            rbacService.setAllPermissions(allPermissions);

            var sessionInfo = SessionService.getSessionInfo();
            var appAccess = [];

            // [1] Customer if they have an org with an id
            if (sessionInfo && sessionInfo.organization && sessionInfo.organization.id) {
                appAccess.push(rolesConstant.CUSTOMER);
            }

            if (sessionInfo && sessionInfo.roles) {
                // [1] Customer if they have an org with an id
                if (sessionInfo && sessionInfo.organization && sessionInfo.organization.id) {
                    appAccess.push(rolesConstant.CUSTOMER);
                }

                // [2] Admin
                if (rbacService.canViewAdminPortal(sessionInfo.roles)) {
                    appAccess.push(rolesConstant.ADMIN);
                }

                // [3] MFG
                if (rbacService.canViewMfgPortal(sessionInfo.roles)) {
                    appAccess.push(rolesConstant.MFG);
                }
                // [4] LINK - everyone has an access to LINK
                appAccess.push(rolesConstant.LINK);
            }

            SessionService.setAppAccess(appAccess);

        }

        return service;

    }

})();
